import { render, staticRenderFns } from "./_page.vue?vue&type=template&id=9435c4c0"
import script from "./_page.vue?vue&type=script&lang=js"
export * from "./_page.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InfoPage: require('/vercel/path0/packages/dashboard/components/storyblok/InfoPage.vue').default})
