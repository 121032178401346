
import InfoPage from "@/components/InfoPage"
import { useNuxtApp } from "#app"
import {
  AnalyticsEvent,
  AnalyticsEventPageId,
  InfoPage as InfoPageId,
} from "@evercam/shared/types"

export default {
  components: {
    InfoPage,
  },
  meta: {
    public: true,
  },
  middleware: "infoPagesRedirect",
  mounted() {
    const infoPageIdsAnalyticsEventPageIdsMap = {
      [InfoPageId.BimCompare]: AnalyticsEventPageId.BimCompareInfo,
      [InfoPageId.EvercamGo]: AnalyticsEventPageId.EvercamGoInfo,
    }
    useNuxtApp().nuxt2Context.route.meta[0].pageId =
      infoPageIdsAnalyticsEventPageIdsMap[this.$route.params.page]
    this.$analytics.saveEvent(AnalyticsEvent.PageView)
  },
}
